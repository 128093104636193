// import Lenis from 'lenis';
import * as fpsmeter from 'fpsmeter';

const distancePercentage = 80;
const distancePercentageRandomness = 20;
const listeners = [];

export function bootstrap() {
  // FPS meter for debugging
  var meter = new FPSMeter({
    position: 'fixed',
    zIndex: 10000,
    interval: 50,
    smoothing: 2,
    decimals: 0
  });

  window.listeners = listeners;

  document.addEventListener('scroll', e => {
    requestAnimationFrame(() => {
      meter.tickStart();
      onScroll();
      meter.tick();
    });
  }, { passive: true });

  // Parallax animation
  document.querySelectorAll('.c-home-grid__feature').forEach(featureEl => {
    const img = featureEl.querySelector('img');

    if (img.complete) {
      setupListener(featureEl);
    } else {
      img.addEventListener('load', e => {
        setupListener(featureEl);
      });
    }
  });
}

function onScroll() {
  listeners.forEach(listener => {
    if (listener.shouldUpdate) updatePosition(listener);
  });
}

function setupListener(container) {
  const element = container.querySelector('figure');
  const index = listeners.length;
  const factor = getDistanceFactor();
  const distance = container.clientHeight * factor;
  
  listeners[index] = {
    index, container, element, distance, factor
  };
  
  const io = new IntersectionObserver(entries => {
    const startPrev = listeners[index].start;
    listeners[index].shouldUpdate = entries[0].isIntersecting;
    listeners[index].start = container.offsetTop - distance - window.innerHeight,
    listeners[index].end = container.offsetTop + container.clientHeight + distance;

    if (listeners[index].start !== startPrev) {
      requestAnimationFrame(onScroll);
    }
  }, {
    rootMargin: `${distance}px 0px ${distance}px 0px`
  });

  io.observe(container);
}

function updatePosition(listener) {
  const progress = (window.scrollY - listener.start) / (listener.end - listener.start),
        offset = (listener.distance * progress * 2) - listener.distance;
  
  listener.progress = progress;
  listener.offset = offset;
  
  listener.element.style.setProperty('transform', `translateY(${offset}px)`);
}

function getDistanceFactor() {
  let delta = 0;
  if (distancePercentageRandomness !== 0) {
    delta = Math.random() * distancePercentageRandomness*2 - distancePercentageRandomness;
  }

  return (distancePercentage + delta) / 100;
}