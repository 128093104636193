export function bootstrap() {
  const imgs = document.querySelectorAll('img[loading=lazy]');
  imgs.forEach(img => {
    if (img.complete) {
      img.classList.add('loaded');
    } else {
      img.addEventListener('load', () => {
        img.classList.add('loaded');
      });
    }
  });
} 