import { load } from './loader';
import * as gototopButton from './modules/gototopButton';
import * as fitText from './modules/fitText';
import * as homeParallax from './modules/homeParallax';
import * as homeBringToFront from './modules/homeBringToFront';
import * as lazyLoad from './modules/imageLazyload';

load([
  gototopButton,
  fitText,
  homeParallax,
  homeBringToFront,
  lazyLoad
]);
